<template>
  <table class="table">
    <tbody>
      <template v-for="(asset, index) in assets">
        <tr :key="`${asset.displayIndex}-asset`">
          <template v-if="assets.length === 1">
            <td colspan="2">
              <span class="text-strong h6">{{ asset.name }}</span>
            </td>
          </template>
          <template v-else>
            <td>
              {{ $t('YOUR_PLAN_TABLE.CATEGORY_1') }}
              {{ index + 1 }}
            </td>
            <td class="text-right">
              <div>
                <span class="text-strong h6">{{ asset.name }}</span>
                <p
                  v-for="asset in assets"
                  :key="`${asset.displayIndex}-asset`"
                  class="h6 text-normal"
                >
                  +&nbsp;{{ asset.name }}
                </p>
              </div>
            </td>
          </template>
        </tr>
        <tr :key="`${asset.displayIndex}-${index}`">
          <td colspan="2" class="no-padding">
            <table class="table">
              <tr
                :key="`${serviceType}-${key}`"
                v-for="(serviceType, key) in SERVICE_TYPES"
              >
                <td class="table__icon table__icon--no-padding">
                  <StihlServiceIcon
                    :icon-type="serviceType"
                    class="icon table__icon-icon"
                  />
                  <template v-if="serviceType === 'winter_storage'">
                    <p
                      v-if="bundleModel === 'IMOW_RMI'"
                      class="table__icon-text"
                    >
                      {{ $t(`SERVICES.WINTER_STORAGE_RMI`) }}
                    </p>
                    <p v-else class="table__icon-text">
                      {{ $t(`SERVICES.WINTER_STORAGE_OTHER`) }}
                    </p>
                  </template>
                  <template v-else>
                    <p class="table__icon-text">
                      {{ $t(`SERVICES.${serviceType.toUpperCase()}`) }}
                    </p>
                  </template>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </template>
      <tr v-if="backOfficeLoggedIn">
        <td>{{ $t('BUNDLE_PRODUCT_CODE') }}</td>
        <td class="text-right text-grey">
          {{ bundle.productCode }}
        </td>
      </tr>
      <tr>
        <td>{{ $t('YOUR_PLAN_TABLE.CATEGORY_2') }}</td>
        <td class="text-right text-grey">
          {{ formatPrice(bundle.totalAmount) }} kr.
        </td>
      </tr>
      <tr v-if="showPeriod">
        <td>{{ $t('YOUR_PLAN_TABLE.CATEGORY_5') }}</td>
        <td class="text-right text-grey">
          {{ bundle.renewalPeriodMonths }} {{ $t('MONTHS') }}
        </td>
      </tr>
      <tr v-if="showPeriod">
        <td>{{ $t('YOUR_PLAN_TABLE.CATEGORY_6') }}</td>
        <td class="text-right text-grey">
          {{ bundle.periodMonths }} {{ $t('MONTHS') }}
        </td>
      </tr>
      <tr v-if="showPaymentAmount">
        <td class="text-strong">{{ $t('YOUR_PLAN_TABLE.CATEGORY_TOTAL') }}</td>
        <td class="text-right text-normal">
          <span class="text-strong h6"> {{ bundle.paymentAmount }} kr. </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapState } from 'vuex';
import numeral from 'numeral';

import { constants } from '@/mixins';
import { StihlServiceIcon } from '@/components';

export default {
  name: 'YourPlanTable',
  mixins: [constants],
  components: {
    StihlServiceIcon,
  },
  props: {
    showPeriod: Boolean,
    showPaymentAmount: Boolean,
  },
  methods: {
    formatPrice(price) {
      return numeral(price).format('0,0').replace(',', ' ');
    },
  },
  computed: {
    ...mapState(['selectedBundle', 'backOfficeLoggedIn']),
    assets() {
      return this.selectedBundle.assets;
    },
    bundle() {
      return this.selectedBundle || {};
    },
    bundleModel() {
      return this.bundle && this.bundle.additionalData.model;
    },
  },
};
</script>

<style lang="scss">
.table__icon {
  position: relative;
  padding-top: rem(4px);
  padding-bottom: rem(4px);
  padding-left: 1rem;

  @include min-width(sm) {
    padding-left: 2rem;
  }
}

.table__icon--no-padding {
  padding-left: 0;
}

.table__icon-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: $color-white;
  font-size: rem(30px);

  @include min-width(sm) {
    left: 2rem;
  }

  .table__icon--no-padding & {
    left: 0;
  }
}

.table__icon-text {
  padding-left: rem(48px);
}
</style>
